<template>
  <div
    class="py-20 bg-gradient-to-b from-white to-slate-50"
    ref="counterSection"
  >
    <div class="container mx-auto px-4">
      <!-- Header Section -->
      <div class="max-w-4xl mx-auto text-center mb-16">
        <h2 class="text-4xl md:text-5xl font-bold text-logo-color">
          Transforming Businesses Through Strategic Growth
        </h2>
        <p class="text-lg text-slate-600">
          Setting new standards in business transformation and enterprise
          development
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <!-- Revenue Growth -->
        <div
          class="group"
          :class="{
            'opacity-0 translate-y-8': !isVisible,
            'opacity-100 translate-y-0 transition-all duration-1000': isVisible,
          }"
        >
          <div
            class="bg-white p-8 rounded-xl hover:shadow-xl transition-all duration-300 border border-slate-100"
          >
            <div class="flex items-start mb-4">
              <div class="flex-1">
                <div class="text-5xl font-bold text-slate-800 mb-1">
                  {{ counters.revenue
                  }}<span class="text-2xl font-medium">%</span>
                </div>
                <h3 class="text-lg font-semibold text-slate-700 mb-2">
                  Average Revenue Growth
                </h3>
                <p class="text-slate-500 text-sm">
                  For enterprise clients in first year
                </p>
              </div>
              <div
                class="text-teal-500 group-hover:scale-110 transition-transform duration-300"
              >
                <svg
                  class="w-12 h-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                  />
                </svg>
              </div>
            </div>
            <div class="h-2 bg-slate-100 rounded-full overflow-hidden">
              <div
                class="h-full bg-teal-500 rounded-full transition-all duration-1000"
                :style="{ width: `${counters.revenue}%` }"
              ></div>
            </div>
          </div>
        </div>

        <!-- Market Expansion -->
        <div
          class="group"
          :class="{
            'opacity-0 translate-y-8': !isVisible,
            'opacity-100 translate-y-0 transition-all duration-1000 delay-200':
              isVisible,
          }"
        >
          <div
            class="bg-white p-8 rounded-xl hover:shadow-xl transition-all duration-300 border border-slate-100"
          >
            <div class="flex items-start mb-4">
              <div class="flex-1">
                <div class="text-5xl font-bold text-slate-800 mb-1">
                  {{ counters.markets
                  }}<span class="text-2xl font-medium">+</span>
                </div>
                <h3 class="text-lg font-semibold text-slate-700 mb-2">
                  New Markets Entered
                </h3>
                <p class="text-slate-500 text-sm">
                  Successfully expanded businesses
                </p>
              </div>
              <div
                class="text-teal-500 group-hover:scale-110 transition-transform duration-300"
              >
                <svg
                  class="w-12 h-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <div class="h-2 bg-slate-100 rounded-full overflow-hidden">
              <div
                class="h-full bg-teal-500 rounded-full transition-all duration-1000"
                :style="{ width: `${(counters.markets / 50) * 100}%` }"
              ></div>
            </div>
          </div>
        </div>

        <!-- Enterprise Clients -->
        <div
          class="group"
          :class="{
            'opacity-0 translate-y-8': !isVisible,
            'opacity-100 translate-y-0 transition-all duration-1000 delay-300':
              isVisible,
          }"
        >
          <div
            class="bg-white p-8 rounded-xl hover:shadow-xl transition-all duration-300 border border-slate-100"
          >
            <div class="flex items-start mb-4">
              <div class="flex-1">
                <div class="text-5xl font-bold text-slate-800 mb-1">
                  {{ counters.clients
                  }}<span class="text-2xl font-medium">K</span>
                </div>
                <h3 class="text-lg font-semibold text-slate-700 mb-2">
                  Enterprise Clients
                </h3>
                <p class="text-slate-500 text-sm">
                  Active partnerships worldwide
                </p>
              </div>
              <div
                class="text-teal-500 group-hover:scale-110 transition-transform duration-300"
              >
                <svg
                  class="w-12 h-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
            </div>
            <div class="h-2 bg-slate-100 rounded-full overflow-hidden">
              <div
                class="h-full bg-teal-500 rounded-full transition-all duration-1000"
                :style="{ width: `${(counters.clients / 10) * 100}%` }"
              ></div>
            </div>
          </div>
        </div>

        <!-- Business Efficiency -->
        <div
          class="group"
          :class="{
            'opacity-0 translate-y-8': !isVisible,
            'opacity-100 translate-y-0 transition-all duration-1000 delay-400':
              isVisible,
          }"
        >
          <div
            class="bg-white p-8 rounded-xl hover:shadow-xl transition-all duration-300 border border-slate-100"
          >
            <div class="flex items-start mb-4">
              <div class="flex-1">
                <div class="text-5xl font-bold text-slate-800 mb-1">
                  {{ counters.efficiency
                  }}<span class="text-2xl font-medium">%</span>
                </div>
                <h3 class="text-lg font-semibold text-slate-700 mb-2">
                  Efficiency Increase
                </h3>
                <p class="text-slate-500 text-sm">
                  Average operational improvement
                </p>
              </div>
              <div
                class="text-teal-500 group-hover:scale-110 transition-transform duration-300"
              >
                <svg
                  class="w-12 h-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M13 10V3L4 14h7v7l9-11h-7z"
                  />
                </svg>
              </div>
            </div>
            <div class="h-2 bg-slate-100 rounded-full overflow-hidden">
              <div
                class="h-full bg-teal-500 rounded-full transition-all duration-1000"
                :style="{ width: `${counters.efficiency}%` }"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BusinessGrowthStatistics",
  data() {
    return {
      counters: {
        revenue: 0,
        markets: 0,
        clients: 0,
        efficiency: 0,
      },
      targets: {
        revenue: 85, // 85% Average Revenue Growth
        markets: 45, // 45+ New Markets
        clients: 8.5, // 8.5K Enterprise Clients
        efficiency: 65, // 65% Efficiency Increase
      },
      isVisible: false,
      hasAnimated: false,
    };
  },
  mounted() {
    this.setupIntersectionObserver();
  },
  methods: {
    setupIntersectionObserver() {
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.3,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.hasAnimated) {
            this.isVisible = true;
            this.startCounting();
            this.hasAnimated = true;
          }
        });
      }, options);

      observer.observe(this.$refs.counterSection);
    },
    startCounting() {
      const duration = 2000;
      const steps = 50;
      const interval = duration / steps;

      const animate = () => {
        Object.keys(this.targets).forEach((key) => {
          const target = this.targets[key];
          const increment = target / steps;
          if (this.counters[key] < target) {
            this.counters[key] = Math.min(
              parseFloat((this.counters[key] + increment).toFixed(1)),
              target
            );
          }
        });

        if (
          Object.keys(this.counters).some(
            (key) => this.counters[key] < this.targets[key]
          )
        ) {
          setTimeout(animate, interval);
        }
      };

      animate();
    },
  },
};
</script>
