<template>
  <div class="home-view">
    <PreLoader :loading="isLoading" />
    <hero-page />
  </div>
</template>

<script setup>
import HeroPage from "@/components/HeroPage.vue";
import { onMounted } from "vue";
import PreLoader from "@/shared/PreLoader.vue";
import { usePreloader } from "@/composables/usePreloader";

const { isLoading, startLoading, stopLoading, waitForImages } = usePreloader();

onMounted(async () => {
  await startLoading();
  try {
    // Wait for any data loading and images
    await waitForImages();
  } catch (error) {
    console.error("Error loading contact page:", error);
  } finally {
    await stopLoading();
  }
});
</script>

<style scoped>
.home-view {
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

/* Ensure PreLoader stays within bounds */
:deep(.preloader) {
  width: 100%;
  overflow-x: hidden;
}

/* Ensure HeroPage stays within bounds */
:deep(.hero-page) {
  width: 100%;
  overflow-x: hidden;
}
</style>
