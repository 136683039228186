# ModernHeroCarousel.vue
<template>
  <div
    class="relative overflow-hidden bg-gradient-to-br from-slate-900 to-slate-800"
  >
    <!-- Main Carousel Container -->
    <div
      class="relative h-[550px] sm:h-[600px] md:h-[600px] lg:h-[700px] xl:h-screen xl:max-h-[800px]"
    >
      <!-- Slides Container -->
      <transition-group name="fade" tag="div" class="h-full">
        <div
          v-for="(slide, index) in slides"
          :key="index"
          v-show="currentSlide === index"
          class="absolute inset-0 transition-all duration-700"
        >
          <!-- Background Image with Overlay -->
          <div class="absolute inset-0 overflow-hidden">
            <img
              :src="slide.image"
              :alt="slide.title"
              class="w-full h-full object-cover transform transition-transform duration-[2000ms] scale-110"
              :class="{ 'scale-100': currentSlide === index }"
            />
            <div
              class="absolute inset-0 bg-gradient-to-r from-slate-900/90 via-slate-800/80 to-slate-900/70"
            ></div>
          </div>

          <!-- Content Container -->
          <div class="relative h-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div
              class="flex flex-col justify-center h-full max-w-2xl md:max-w-xl lg:max-w-2xl"
            >
              <!-- Service Badge -->
              <div
                class="inline-flex items-center space-x-3 mb-4 sm:mb-6 opacity-0 animate-slideUp"
                :style="{ animationDelay: '200ms' }"
              >
                <span
                  class="px-3 py-1 rounded-full bg-indigo-500/20 text-indigo-300 text-xs sm:text-sm font-medium"
                >
                  Service {{ slide.number }}
                </span>
              </div>

              <!-- Title -->
              <h2
                class="text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-white mb-4 sm:mb-6 lg:mb-8 leading-tight opacity-0 animate-slideUp"
                :style="{ animationDelay: '400ms' }"
              >
                {{ slide.title }}
              </h2>

              <!-- Description -->
              <p
                class="text-slate-300 text-base sm:text-lg md:text-base lg:text-lg mb-6 sm:mb-8 lg:mb-10 leading-relaxed opacity-0 animate-slideUp line-clamp-3 md:line-clamp-4 lg:line-clamp-none"
                :style="{ animationDelay: '600ms' }"
              >
                {{ slide.description }}
              </p>
            </div>
          </div>
        </div>
      </transition-group>

      <!-- Navigation Arrows - Hidden on Small Screens and Nest Hub Devices -->
      <div
        class="hidden xl:flex absolute inset-0 items-center justify-between p-4"
      >
        <button
          @click="prevSlide"
          class="p-2 rounded-full bg-slate-800/50 text-white hover:bg-slate-700/50 transition-all duration-300 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-slate-900"
          aria-label="Previous slide"
        >
          <svg
            class="w-5 h-5 sm:w-6 sm:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          @click="nextSlide"
          class="p-2 rounded-full bg-slate-800/50 text-white hover:bg-slate-700/50 transition-all duration-300 backdrop-blur-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-slate-900"
          aria-label="Next slide"
        >
          <svg
            class="w-5 h-5 sm:w-6 sm:h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      <!-- Progress Indicators -->
      <div
        class="absolute bottom-4 sm:bottom-8 left-1/2 -translate-x-1/2 flex items-center space-x-3 md:space-x-4"
      >
        <button
          v-for="(_, index) in slides"
          :key="index"
          @click="goToSlide(index)"
          class="group relative h-2 md:h-3 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-slate-900"
          :class="[
            currentSlide === index
              ? 'w-8 md:w-10 bg-indigo-500'
              : 'w-2 md:w-3 bg-slate-500/50 hover:bg-slate-400/50',
            'rounded-full overflow-hidden',
          ]"
          :aria-label="`Go to slide ${index + 1}`"
          :aria-current="currentSlide === index"
        >
          <div
            class="absolute inset-0 bg-indigo-500 transform origin-left transition-transform duration-5000"
            :class="{ 'scale-x-100': currentSlide === index }"
            :style="{
              transform: currentSlide === index ? 'scaleX(1)' : 'scaleX(0)',
            }"
          ></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModernHeroCarousel",
  data() {
    return {
      currentSlide: 0,
      slides: [
        {
          number: "01",
          title: "Empowering Institutional Excellence",
          description:
            "Transform your organization with our cutting-edge institutional strengthening solutions. We build resilient frameworks that drive sustainable growth and long-term success.",
          image: require("@/assets/caro1.jpeg"),
        },
        {
          number: "02",
          title: "Strategic Business Solutions",
          description:
            "Elevate your business performance with our innovative solutions. We optimize operations and drive growth through data-driven strategies and proven methodologies.",
          image: require("@/assets/caro2.jpeg"),
        },
        {
          number: "03",
          title: "Interactive Workshops",
          description:
            "Unlock your team's potential through our dynamic workshop programs. Experience hands-on learning that delivers immediate impact and lasting transformation.",
          image: require("@/assets/caro3.jpeg"),
        },
        {
          number: "04",
          title: "Expert Business Advisory",
          description:
            "Navigate complex challenges with confidence through our strategic advisory services. Get insights and solutions that drive measurable business outcomes.",
          image: require("@/assets/caro1.jpeg"),
        },
        {
          number: "05",
          title: "Professional Accounting",
          description:
            "Ensure financial excellence with our comprehensive accounting solutions. We deliver accuracy, compliance, and strategic financial management for sustainable growth.",
          image: require("@/assets/caro2.jpeg"),
        },
      ],
      autoplayInterval: null,
    };
  },
  mounted() {
    this.startAutoplay();
    this.handleTouchEvents();
  },
  beforeUnmount() {
    this.stopAutoplay();
    this.removeTouchEvents();
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      this.resetAutoplay();
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.slides.length) % this.slides.length;
      this.resetAutoplay();
    },
    goToSlide(index) {
      this.currentSlide = index;
      this.resetAutoplay();
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(this.nextSlide, 5000);
    },
    stopAutoplay() {
      if (this.autoplayInterval) {
        clearInterval(this.autoplayInterval);
      }
    },
    resetAutoplay() {
      this.stopAutoplay();
      this.startAutoplay();
    },
    handleTouchEvents() {
      this.touchStartX = 0;
      this.touchEndX = 0;

      const element = document.querySelector(".h-full");
      if (element) {
        element.addEventListener("touchstart", this.handleTouchStart, {
          passive: true,
        });
        element.addEventListener("touchend", this.handleTouchEnd, {
          passive: true,
        });
      }
    },
    removeTouchEvents() {
      const element = document.querySelector(".h-full");
      if (element) {
        element.removeEventListener("touchstart", this.handleTouchStart);
        element.removeEventListener("touchend", this.handleTouchEnd);
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
    },
    handleTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      this.handleSwipe();
    },
    handleSwipe() {
      const swipeThreshold = 50;
      const diff = this.touchStartX - this.touchEndX;

      if (Math.abs(diff) > swipeThreshold) {
        if (diff > 0) {
          this.nextSlide();
        } else {
          this.prevSlide();
        }
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scale(1.05);
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
  transform: scale(1);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-slideUp {
  animation: slideUp 0.7s ease-out forwards;
}

.duration-5000 {
  transition-duration: 5000ms;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.h-full {
  -webkit-user-select: none;
  user-select: none;
  touch-action: pan-y pinch-zoom;
}
</style>
