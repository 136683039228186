<template>
  <decades-expi /><why-chooseus /><question-answers /><ask-as />
</template>

<script>
import QuestionAnswers from "@/components/AboutDir/QuestionAnswers.vue";
import WhyChooseus from "@/components/AboutDir/WhyChooseus.vue";
import AskAs from "@/components/AskAs.vue";

import DecadesExpi from "@/components/AboutDir/DecadesExpi.vue";

export default {
  components: {
    AskAs,
    DecadesExpi,
    WhyChooseus,
    QuestionAnswers,
  },
  name: "AboutHero",
};
</script>

<style></style>
