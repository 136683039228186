<script>
export default {
  name: "ModernServices",
  data() {
    return {
      activeCard: null,
      services: [
        {
          imageSrc: "strength12.jpg",
          title: "Institutional Strengthening",
          description:
            "Transform your organization with robust frameworks and innovative strategies for sustainable growth.",
          features: [
            "Strategic Planning",
            "Process Optimization",
            "Change Management",
            "Performance Metrics",
          ],
          link: "/services",
        },
        {
          imageSrc: "2150917238.jpg",
          title: "Business Solutions",
          description:
            "Optimize your operations with cutting-edge business solutions tailored to your needs.",
          features: [
            "Digital Transformation",
            "Process Automation",
            "Data Analytics",
            "System Integration",
          ],
          link: "/services",
        },
        {
          imageSrc: "workshop.jpg",
          title: "Workshop Facilitation",
          description:
            "Empower your team through interactive workshops and skill development programs.",
          features: [
            "Leadership Training",
            "Team Building",
            "Skills Development",
            "Innovation Workshops",
          ],
          link: "/services",
        },
        {
          imageSrc: "shaking.png",
          title: "Strategic Advisory",
          description:
            "Navigate complex business challenges with expert guidance and proven strategies.",
          features: [
            "Risk Management",
            "Growth Strategy",
            "Market Analysis",
            "Financial Planning",
          ],
          link: "/services",
        },
      ],
    };
  },
  methods: {
    setActiveCard(index) {
      this.activeCard = this.activeCard === index ? null : index;
    },
  },
};
</script>

<template>
  <section class="min-h-screen bg-slate-50 py-5 px-4 sm:px-6 lg:px-8">
    <!-- Header -->
    <div class="max-w-5xl mx-auto text-center mb-20">
      <h1
        class="text-6xl md:text-7xl lg:text-8xl font-bold text-slate-900 mb-8 tracking-tight"
      >
        <span
          class="block text-transparent bg-clip-text bg-logo-color animate-gradient-x slide-down"
        >
          Transform
        </span>
        <span class="block slide-up text-logo-color"> Your Business </span>
      </h1>
      <p class="text-slate-700 text-xl fade-in max-w-2xl mx-auto">
        Discover innovative solutions designed to elevate your organization
      </p>
    </div>

    <!-- Services Grid -->
    <div class="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
      <div
        v-for="(service, index) in services"
        :key="index"
        class="group relative"
        :class="{ 'md:col-span-2': index === 0 }"
        @click="setActiveCard(index)"
      >
        <!-- Card -->
        <div
          class="relative overflow-hidden rounded-2xl transition-all duration-500 cursor-pointer bg-white shadow-lg hover:shadow-xl service-card"
          :class="{ 'is-active': activeCard === index }"
        >
          <!-- Background Image and Overlay System -->
          <div class="absolute inset-0 transition-all duration-700">
            <!-- Base Image -->
            <img
              :src="require(`@/assets/${service.imageSrc}`)"
              :alt="service.title"
              class="w-full h-full object-cover transition-all duration-1000"
              :class="{
                'scale-105': activeCard === index,
                'scale-100': activeCard !== index,
              }"
            />

            <!-- Single Overlay Layer with Blur -->
            <div
              class="absolute inset-0 bg-black/30 backdrop-blur-md transition-all duration-500"
              :class="{
                'opacity-0 backdrop-blur-none': activeCard !== index,
                'opacity-100': activeCard === index,
              }"
            ></div>

            <!-- Subtle Gradient for Text Readability -->
            <div
              class="absolute inset-0 bg-gradient-to-b from-transparent to-black/50 transition-opacity duration-500"
            ></div>
          </div>

          <!-- Content Container with Enhanced Contrast -->
          <div
            class="relative p-8 h-full flex flex-col justify-end z-20 text-shadow-lg"
          >
            <!-- Title -->
            <h3
              class="text-2xl font-bold text-white mb-4 transform transition-transform duration-500"
              :class="{
                'translate-y-0 text-3xl': activeCard === index,
                'translate-y-8': activeCard !== index,
              }"
            >
              {{ service.title }}
            </h3>

            <!-- Description -->
            <div
              class="overflow-hidden transition-all duration-500"
              :class="{
                'max-h-96 opacity-100': activeCard === index,
                'max-h-0 opacity-0': activeCard !== index,
              }"
            >
              <p class="text-white mb-6 text-lg font-medium">
                {{ service.description }}
              </p>

              <!-- Features -->
              <ul class="space-y-4 mb-8">
                <li
                  v-for="(feature, idx) in service.features"
                  :key="idx"
                  class="flex items-center text-white/90"
                >
                  <svg
                    class="w-5 h-5 mr-3 text-violet-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  {{ feature }}
                </li>
              </ul>

              <!-- CTA Button -->
              <router-link
                :to="service.link"
                class="inline-flex items-center px-6 py-3 rounded-lg bg-gradient-to-r from-violet-600 to-indigo-600 text-white font-medium transform transition-all duration-300 hover:scale-105 hover:shadow-lg"
              >
                Learn More
                <svg
                  class="w-5 h-5 ml-2 animate-bounce-x"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* Text Shadow for Better Readability */
.text-shadow-lg {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Card Animations */
.service-card {
  height: 300px;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.3)
  );
}

.service-card.is-active {
  height: 550px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
}

/* Background Image Scale */
.group:hover img {
  transform: scale(105%);
}

/* Slide Down Animation */
.slide-down {
  animation: slideDown 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fade In Animation */
.fade-in {
  animation: fadeIn 1s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Bounce Animation */
.animate-bounce-x {
  animation: bounceX 1s infinite;
}

@keyframes bounceX {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(4px);
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .service-card {
    height: 250px;
  }

  .service-card.is-active {
    height: 500px;
  }

  .text-3xl {
    font-size: 1.75rem;
  }
}

/* Gradient Animation */
@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient-x {
  background-size: 200% 100%;
  animation: gradientFlow 4s ease infinite;
}

/* Slide Up Animation */
.slide-up {
  animation: slideUp 0.8s cubic-bezier(0.4, 0, 0.2, 1) 0.2s both;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Ensure smooth transitions */
* {
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
}
</style>
