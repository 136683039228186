<template>
  <div class="min-h-screen bg-gradient-to-b from-gray-50 to-white pt-16">
    <!-- Hero Section -->
    <div class="relative h-[60vh] overflow-hidden">
      <div class="absolute inset-0 bg-black/40 z-10"></div>
      <div
        class="absolute inset-0 bg-cover bg-center"
        :style="{
          backgroundImage: `url(${require('@/assets/contactus.jpeg?lazy')})`,
          loading: 'lazy',
        }"
      ></div>
      <div
        class="relative z-20 container mx-auto px-4 h-full flex items-center"
      >
        <div class="max-w-2xl">
          <h1 class="text-5xl md:text-7xl font-bold text-white mb-6">
            Let's Create <br />
            <span class="text-white">Something Amazing</span>
          </h1>
          <p class="text-xl text-gray-200 mb-8">
            Transform your vision into reality with our expert team
          </p>
        </div>
      </div>
    </div>

    <!-- Contact Information Cards -->
    <div class="container mx-auto px-4 -mt-20 relative z-30">
      <div class="grid md:grid-cols-3 gap-8">
        <div
          v-for="(card, index) in contactCards"
          :key="index"
          class="bg-white rounded-xl shadow-xl p-6 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
        >
          <div
            :class="[
              'w-14 h-14 rounded-full flex items-center justify-center mb-4',
              card.bgColor,
            ]"
          >
            <i :class="['text-2xl', card.icon, card.iconColor]"></i>
          </div>
          <h3 class="text-xl font-semibold mb-2">{{ card.title }}</h3>
          <p class="text-gray-600 mb-4">{{ card.description }}</p>
          <a
            :href="card.link"
            :class="{
              'text-[#164364] hover:text-slate-700 flex items-center space-x-2 group': true,
            }"
            :target="card.isPhone ? '_self' : '_blank'"
          >
            <span>{{ card.linkText }}</span>
            <i
              class="fas fa-arrow-right transition-transform group-hover:translate-x-1"
            ></i>
          </a>
        </div>
      </div>
    </div>
  </div>
  <ask-as />
</template>

<script>
import AskAs from "../AskAs.vue";

export default {
  components: { AskAs },
  name: "ContactPage",
  setup() {
    const contactCards = [
      {
        title: "Chat with Us",
        description: "Get instant support from our team through live chat",
        icon: "fas fa-comments",
        bgColor: "bg-[#164364]/10",
        iconColor: "text-blue-600",
        link: "https://wa.me/+233558374161",
        linkText: "Start Chat",
        isPhone: false,
      },
      {
        title: "Email Us",
        description: "Send us an email and we'll respond within 24 hours",
        icon: "fas fa-envelope",
        bgColor: "bg-purple-50",
        iconColor: "text-purple-600",
        link: "mailto:info@orsonrede.com",
        linkText: "Send Email",
        isPhone: false,
      },
      {
        title: "Call Us",
        description: "Talk directly with our experts at your convenience",
        icon: "fas fa-phone-alt",
        bgColor: "bg-green-50",
        iconColor: "text-green-600",
        link: "tel:+233558374161",
        linkText: "Call Now",
        isPhone: true,
      },
    ];

    return {
      contactCards,
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

[v-cloak] {
  display: none;
}
</style>
