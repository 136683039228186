<template>
  <section
    class="relative bg-gradient-to-br from-gray-50 via-white to-gray-100 py-16 md:py-24"
  >
    <div class="container mx-auto px-4">
      <div class="max-w-4xl mx-auto text-center mb-12">
        <h2
          class="text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-logo-color mb-4 tracking-tight"
        >
          Empowering Connections, Expanding Horizons
        </h2>
        <p
          class="text-lg md:text-xl text-gray-600 max-w-2xl mx-auto leading-relaxed"
        >
          “We’re proud to have worked with an incredible lineup of clients and
          partners who trust us to help them navigate the world of finance.
          Check out some of the amazing people and organizations we’ve had the
          privilege to collaborate with.”
        </p>
      </div>

      <!-- Partners Slider Container -->
      <div class="relative overflow-hidden group bg-white">
        <!-- Background set to white -->
        <!-- Gradient Overlay -->
        <div
          class="absolute inset-y-0 left-0 w-24 md:w-32 bg-gradient-to-r from-white to-transparent z-10 pointer-events-none"
        ></div>
        <div
          class="absolute inset-y-0 right-0 w-24 md:w-32 bg-gradient-to-l from-white to-transparent z-10 pointer-events-none"
        ></div>

        <!-- Slider Wrapper -->
        <div
          ref="sliderWrapper"
          class="relative w-full overflow-hidden bg-white"
        >
          <!-- Background set to white -->
          <!-- Slider Track -->
          <div
            ref="sliderTrack"
            class="flex items-center transition-transform duration-[40000ms] ease-linear"
            :style="{ transform: `translateX(${translateX}px)` }"
          >
            <!-- Duplicate logos to create infinite effect -->
            <div
              v-for="(logo, index) in [...logos, ...logos, logos[0]]"
              :key="index"
              class="flex-shrink-0 flex items-center justify-center mx-6 md:mx-10 lg:mx-14 transition-all duration-300"
            >
              <img
                :src="logo"
                :alt="`Partner Logo ${index + 1}`"
                class="max-w-[120px] max-h-[120px] md:max-w-[150px] md:max-h-[150px] object-contain opacity-60 hover:opacity-100 hover:scale-110 transition-all duration-500 ease-in-out grayscale hover:grayscale-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Background Decorative Elements -->
    <div class="absolute top-0 left-0 right-0 bottom-0 pointer-events-none">
      <div
        class="absolute top-0 right-0 w-48 h-48 bg-purple-100/50 rounded-full blur-3xl -translate-y-1/4 translate-x-1/4"
      ></div>
      <div
        class="absolute bottom-0 left-0 w-64 h-64 bg-blue-100/50 rounded-full blur-3xl translate-y-1/4 -translate-x-1/4"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InfiniteSlider",
  data() {
    return {
      logos: [
        require("@/assets/logo.webp"),
        require("@/assets/Mastercard_Foundation.jpg"),
        require("@/assets/aemg_logo.png"),
        require("@/assets/charity.jpg"),
        require("@/assets/afridelf.jpg"),
        // Add more logos as needed
      ],
      translateX: 0,
      animationFrameId: null,
    };
  },
  mounted() {
    this.startInfiniteScroll();
    window.addEventListener("resize", this.resetScroll);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resetScroll);
    this.stopInfiniteScroll();
  },
  methods: {
    startInfiniteScroll() {
      const track = this.$refs.sliderTrack;
      const wrapper = this.$refs.sliderWrapper;

      if (!track || !wrapper) return;

      const animateScroll = () => {
        // Calculate total width of logos
        const logoElements = track.children;
        const totalWidth = Array.from(logoElements).reduce(
          (acc, el) =>
            acc +
            el.offsetWidth +
            parseInt(window.getComputedStyle(el).marginRight),
          0
        );

        // Move the track
        this.translateX -= 1; // Adjust speed by changing this value

        // Reset position when reached the calculated point
        if (Math.abs(this.translateX) >= totalWidth / 2) {
          this.translateX = 0;
        }

        // Continue animation
        this.animationFrameId = requestAnimationFrame(animateScroll);
      };

      // Start the animation
      this.animationFrameId = requestAnimationFrame(animateScroll);
    },
    stopInfiniteScroll() {
      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
      }
    },
    resetScroll() {
      this.stopInfiniteScroll();
      this.translateX = 0;
      this.$nextTick(() => {
        this.startInfiniteScroll();
      });
    },
  },
};
</script>

<style scoped>
/* Additional custom styles if needed */
</style>
