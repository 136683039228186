<template>
  <div class="min-h-screen bg-white">
    <!-- Animated mesh background -->
    <div class="fixed inset-0 -z-10">
      <div class="absolute inset-0">
        <div
          class="absolute top-0 -left-20 w-[500px] h-[500px] bg-[#164364]/10 rounded-full mix-blend-multiply filter blur-3xl animate-morph"
        ></div>
        <div
          class="absolute bottom-0 -right-20 w-[500px] h-[500px] bg-slate-700/10 rounded-full mix-blend-multiply filter blur-3xl animate-morph-delayed"
        ></div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="relative pt-20 pb-32">
      <!-- Header Section -->
      <div class="container mx-auto px-4 sm:px-6 lg:px-8 mb-24">
        <!-- Animated subtitle -->
        <div class="flex justify-center mb-6" data-aos="zoom-in">
          <div
            class="px-4 py-1 rounded-full border border-[#164364]/20 bg-white shadow-sm"
          >
            <div class="flex items-center space-x-2">
              <div
                class="w-2 h-2 rounded-full bg-[#164364] animate-pulse"
              ></div>
              <span
                class="text-sm font-medium text-[#164364] tracking-wide uppercase"
                >Business Services</span
              >
              <div
                class="w-2 h-2 rounded-full bg-[#164364] animate-pulse delay-75"
              ></div>
            </div>
          </div>
        </div>

        <!-- Main title -->
        <div class="text-center" data-aos="fade-up" data-aos-delay="100">
          <h1
            class="text-4xl md:text-6xl lg:text-7xl font-bold text-[#164364] tracking-tight leading-tight mb-8"
          >
            Transform Your
            <span class="relative inline-block">
              <span class="relative z-10">Business</span>
              <div
                class="absolute bottom-2 left-0 w-full h-3 bg-[#164364]/10 -rotate-1"
              ></div>
            </span>
            <br />
            <span class="text-slate-700">With Our Solutions</span>
          </h1>
          <p class="max-w-2xl mx-auto text-lg text-slate-700">
            Comprehensive consulting services designed to elevate your business
            to new heights
          </p>
        </div>
      </div>

      <!-- Services Grid -->
      <div class="container mx-auto px-4 sm:px-6 lg:px-8">
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12"
        >
          <div
            v-for="(service, index) in services"
            :key="index"
            class="group"
            :data-aos="index % 2 === 0 ? 'fade-up' : 'fade-down'"
            :data-aos-delay="index * 100"
          >
            <!-- Card with border -->
            <div class="relative h-full border border-slate-200 rounded-2xl">
              <!-- Background layers -->
              <div
                class="absolute inset-0 bg-gradient-to-br from-white to-slate-50 rounded-2xl shadow-sm group-hover:shadow-xl transition-shadow duration-500"
              ></div>
              <div
                class="absolute inset-0 bg-white rounded-2xl transform transition-transform duration-500 group-hover:-rotate-1"
              ></div>

              <!-- Content -->
              <div
                class="relative bg-white rounded-2xl p-8 transform transition-transform duration-500 group-hover:rotate-1 group-hover:translate-y-1 border border-slate-200"
              >
                <!-- Service number -->
                <div
                  class="absolute -top-4 -right-2 text-8xl font-bold text-[#164364]/10"
                >
                  {{ (index + 1).toString().padStart(2, "0") }}
                </div>

                <!-- Icon -->
                <div class="relative z-10 mb-8">
                  <div class="relative w-16 h-16">
                    <div
                      class="absolute inset-0 bg-[#164364] rounded-xl transform -rotate-6 group-hover:rotate-6 transition-transform"
                    ></div>
                    <div
                      class="absolute inset-[2px] bg-white rounded-xl transform rotate-6 group-hover:-rotate-6 transition-transform"
                    ></div>
                    <div
                      class="absolute inset-0 flex items-center justify-center"
                    >
                      <i
                        :class="[
                          'fas',
                          service.icon,
                          'text-2xl text-[#164364]',
                        ]"
                      ></i>
                    </div>
                  </div>
                </div>

                <!-- Title -->
                <h2 class="text-2xl font-bold text-[#164364] mb-4">
                  {{ service.title }}
                </h2>

                <!-- Description -->
                <p class="text-slate-700 mb-6">{{ service.description }}</p>

                <!-- Features -->
                <div class="space-y-3">
                  <div
                    v-for="(feature, fIndex) in service.features"
                    :key="fIndex"
                    class="flex items-center space-x-3 group/feature"
                  >
                    <div
                      class="flex-shrink-0 w-8 h-8 rounded-lg bg-[#164364]/10 flex items-center justify-center transform transition-transform group-hover/feature:scale-110"
                    >
                      <i class="fas fa-check text-[#164364] text-sm"></i>
                    </div>
                    <span class="text-slate-700">{{ feature }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- CTA Section -->
      <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-24">
        <div class="relative rounded-3xl overflow-hidden" data-aos="fade-up">
          <!-- Background -->
          <div class="absolute inset-0 bg-[#164364]"></div>

          <!-- Content -->
          <div
            class="relative bg-[#164364]/10 backdrop-blur-sm px-8 py-16 md:px-16 md:py-20"
          >
            <div class="max-w-3xl mx-auto text-center text-white">
              <h2 class="text-3xl md:text-4xl font-bold mb-6">
                Ready to Transform Your Business?
              </h2>
              <p class="text-lg mb-8 text-white/80">
                Connect with our experts and begin your journey to success
              </p>

              <!-- Animated button -->
              <button
                @click="showModal = true"
                class="group relative inline-flex items-center px-8 py-3 rounded-xl bg-white font-medium text-[#164364] hover:text-white overflow-hidden transition-colors duration-300"
              >
                <div
                  class="absolute inset-0 bg-[#164364] translate-y-full group-hover:translate-y-0 transition-transform duration-300"
                ></div>
                <span class="relative">Get Started</span>
                <i
                  class="fas fa-arrow-right ml-2 relative transform group-hover:translate-x-1 transition-transform"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact Modal -->
    <div
      v-if="showModal"
      class="fixed inset-0 z-50 overflow-y-auto"
      @click.self="showModal = false"
    >
      <!-- Backdrop -->
      <div class="fixed inset-0 bg-slate-900/50 backdrop-blur-sm"></div>

      <!-- Modal -->
      <div class="relative min-h-screen flex items-center justify-center p-4">
        <div
          class="relative bg-white rounded-2xl p-8 max-w-lg w-full shadow-2xl"
          data-aos="zoom-in"
        >
          <!-- Close button -->
          <button
            @click="showModal = false"
            class="absolute -top-4 -right-4 w-8 h-8 bg-white rounded-full shadow-lg flex items-center justify-center text-slate-700 hover:text-[#164364] transition-colors"
          >
            <i class="fas fa-times"></i>
          </button>

          <!-- Modal content -->
          <div class="text-center">
            <div
              class="h-16 w-16 bg-[#164364]/10 rounded-full flex items-center justify-center mx-auto mb-6"
            >
              <i class="fas fa-envelope text-2xl text-[#164364]"></i>
            </div>
            <h3 class="text-2xl font-bold text-[#164364] mb-6">
              Contact Information
            </h3>

            <!-- Contact details -->
            <div class="space-y-6">
              <!-- Email -->
              <a
                href="mailto:info@orsonrede.com"
                class="group flex items-center justify-center space-x-3 p-4 rounded-xl bg-slate-50 hover:bg-[#164364]/10 transition-colors"
              >
                <i class="fas fa-envelope text-[#164364]"></i>
                <span
                  class="text-slate-700 group-hover:text-[#164364] transition-colors"
                  >info@orsonrede.com</span
                >
              </a>

              <!-- Phone -->
              <a
                href="tel:+1234567890"
                class="group flex items-center justify-center space-x-3 p-4 rounded-xl bg-slate-50 hover:bg-[#164364]/10 transition-colors"
              >
                <i class="fas fa-phone text-[#164364]"></i>
                <span
                  class="text-slate-700 group-hover:text-[#164364] transition-colors"
                  >+233 (558) 374-161</span
                >
              </a>
            </div>

            <!-- Additional text -->
            <p class="mt-6 text-slate-700">
              We'll get back to you within 24 hours
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServicesTypes",
  data() {
    return {
      showModal: false,
      services: [
        {
          title: "Institutional Strengthening",
          description:
            "Elevate your organization with our strategic solutions focused on long-term growth and sustainability.",
          icon: "fa-building",
          features: [
            "Strategic Planning",
            "Process Optimization",
            "Performance Metrics",
          ],
        },
        {
          title: "Business Solutions",
          description:
            "Comprehensive strategies and solutions to drive your business forward and achieve measurable results.",
          icon: "fa-lightbulb",
          features: [
            "Market Analysis",
            "Growth Strategy",
            "Digital Transformation",
          ],
        },
        {
          title: "Workshop Facilitation",
          description:
            "Interactive sessions designed to foster collaboration, innovation, and team development.",
          icon: "fa-users",
          features: ["Team Building", "Innovation Labs", "Leadership Training"],
        },
        {
          title: "Business Advisory",
          description:
            "Expert guidance to optimize operations and maximize business potential.",
          icon: "fa-chart-line",
          features: [
            "Risk Management",
            "Strategic Planning",
            "Performance Analysis",
          ],
        },
        {
          title: "Accountancy Services",
          description:
            "Comprehensive financial solutions ensuring accuracy and compliance.",
          icon: "fa-calculator",
          features: ["Financial Planning", "Tax Services", "Audit Support"],
        },
        {
          title: "HR Solutions",
          description:
            "Transform your workforce with our comprehensive human resource solutions.",
          icon: "fa-user-tie",
          features: [
            "Talent Acquisition",
            "Development Programs",
            "Culture Building",
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
@keyframes morph {
  0%,
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
}

.animate-morph {
  animation: morph 15s ease-in-out infinite;
}

.animate-morph-delayed {
  animation: morph 15s ease-in-out infinite reverse;
  animation-delay: -7.5s;
}

@keyframes gradient {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.animate-gradient {
  animation: gradient 15s ease infinite;
  background-size: 200% auto;
}

.delay-75 {
  animation-delay: 75ms;
}
</style>
