<template>
  <div class="min-h-screen bg-white overflow-hidden">
    <!-- Background decoration with smoother transitions -->
    <div class="absolute inset-0 -z-10">
      <div
        class="absolute top-0 right-0 w-1/2 h-1/2 bg-[#164364]/5 rounded-bl-full transform transition-transform duration-1000"
      ></div>
      <div
        class="absolute bottom-0 left-0 w-1/3 h-1/3 bg-slate-700/5 rounded-tr-full transform transition-transform duration-1000"
      ></div>
    </div>

    <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-24">
      <!-- Main content section -->
      <div class="flex flex-col lg:flex-row items-center gap-12 lg:gap-20">
        <!-- Left side: Image with smoother animations -->
        <div
          class="w-full lg:w-1/2 relative"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-out-cubic"
        >
          <!-- Decorative elements with smoother pulse -->
          <div
            class="absolute -top-8 -left-8 w-64 h-64 bg-[#164364]/10 rounded-full blur-3xl animate-smooth-pulse"
          ></div>
          <div
            class="absolute -bottom-8 -right-8 w-64 h-64 bg-slate-700/10 rounded-full blur-3xl animate-smooth-pulse-delayed"
          ></div>

          <!-- Image container with smoother transitions -->
          <div class="relative group">
            <div
              class="absolute inset-0 bg-[#164364] rounded-2xl transform rotate-6 transition-all duration-700 ease-in-out group-hover:rotate-3"
            ></div>
            <div
              class="relative bg-white p-3 rounded-2xl transform -rotate-3 transition-all duration-700 ease-in-out group-hover:rotate-0 shadow-xl"
            >
              <img
                class="w-full h-auto rounded-xl object-cover transition-transform duration-700 ease-in-out group-hover:scale-[1.02]"
                :src="require('@/assets/Building.jpg')"
                alt="AEMG Ltd."
              />

              <!-- Floating stats cards with smoother animations -->
              <div
                class="absolute -right-12 top-10 bg-white p-4 rounded-xl shadow-lg transition-all duration-500 ease-out hover:shadow-xl transform hover:-translate-y-1"
                data-aos="fade-left"
                data-aos-delay="300"
                data-aos-duration="800"
              >
                <div class="flex items-center space-x-3">
                  <div
                    class="h-10 w-10 bg-[#164364]/10 rounded-lg flex items-center justify-center transform transition-transform duration-500 ease-out hover:scale-110"
                  >
                    <i
                      class="fas fa-chart-line text-[#164364] transition-all duration-300"
                    ></i>
                  </div>
                  <div>
                    <p class="text-sm text-slate-700">Success Rate</p>
                    <p class="text-lg font-bold text-[#164364]">98%</p>
                  </div>
                </div>
              </div>

              <div
                class="absolute -left-12 bottom-10 bg-white p-4 rounded-xl shadow-lg transition-all duration-500 ease-out hover:shadow-xl transform hover:-translate-y-1"
                data-aos="fade-right"
                data-aos-delay="400"
                data-aos-duration="800"
              >
                <div class="flex items-center space-x-3">
                  <div
                    class="h-10 w-10 bg-[#164364]/10 rounded-lg flex items-center justify-center transform transition-transform duration-500 ease-out hover:scale-110"
                  >
                    <i
                      class="fas fa-users text-[#164364] transition-all duration-300"
                    ></i>
                  </div>
                  <div>
                    <p class="text-sm text-slate-700">Happy Clients</p>
                    <p class="text-lg font-bold text-[#164364]">500+</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right side: Content with smoother animations -->
        <div
          class="w-full lg:w-1/2 relative"
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-out-cubic"
        >
          <!-- Animated badge with smoother pulse -->
          <div
            class="inline-flex items-center space-x-2 bg-[#164364]/10 px-4 py-2 rounded-full mb-8 transition-all duration-300 hover:bg-[#164364]/15"
          >
            <div
              class="w-2 h-2 rounded-full bg-[#164364] animate-smooth-pulse"
            ></div>
            <span class="text-sm font-medium text-[#164364]"
              >Our Expertise</span
            >
          </div>

          <!-- Main heading with smoother animations -->
          <h2
            class="text-2xl font-light text-slate-700 mb-4 transition-all duration-300"
          >
            What We Do Best
          </h2>
          <div class="relative mb-8">
            <h1
              class="text-4xl md:text-6xl lg:text-7xl font-serif text-[#164364] leading-tight"
            >
              Areas Of
              <span class="relative inline-block">
                Expertise
                <div
                  class="absolute bottom-2 left-0 w-full h-2 bg-[#164364]/10 -rotate-2 transition-all duration-500 group-hover:rotate-0"
                ></div>
              </span>
            </h1>
          </div>

          <!-- Expertise cards with smoother transitions -->
          <div class="space-y-6 mb-8">
            <div
              v-for="(expertise, index) in expertiseAreas"
              :key="index"
              class="group bg-white rounded-xl p-4 border border-slate-200 transition-all duration-500 ease-out hover:border-[#164364]/30 hover:shadow-lg transform hover:-translate-y-1"
              :data-aos="'fade-up'"
              :data-aos-delay="index * 150"
              :data-aos-duration="800"
            >
              <div class="flex items-center space-x-4">
                <div
                  class="h-12 w-12 bg-[#164364]/10 rounded-lg flex items-center justify-center transition-all duration-500 ease-out group-hover:bg-[#164364]/20 transform group-hover:scale-110"
                >
                  <i
                    :class="[
                      'fas',
                      expertise.icon,
                      'text-[#164364] transition-all duration-300',
                    ]"
                  ></i>
                </div>
                <div>
                  <h3
                    class="text-lg font-semibold text-[#164364] transition-all duration-300"
                  >
                    {{ expertise.title }}
                  </h3>
                  <p class="text-slate-700 transition-all duration-300">
                    {{ expertise.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- Main description with fade animation -->
          <p
            class="text-lg text-slate-700 leading-relaxed mb-8 transition-all duration-500"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1000"
          >
            At Orson rede Consulting, we specialize in strategic financial and
            business consulting services. Our team excels in financial analysis,
            investment advisory, risk management, and customized business
            solutions. With a profound understanding of banking and finance, we
            collaborate closely with you to navigate challenges and seize
            opportunities in today's dynamic business landscape.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpertiseSection",
  data() {
    return {
      expertiseAreas: [
        {
          title: "Financial Analysis",
          description:
            "Comprehensive financial assessment and strategic planning",
          icon: "fa-chart-pie",
        },
        {
          title: "Investment Advisory",
          description: "Expert guidance for optimal investment decisions",
          icon: "fa-money-bill-trend-up",
        },
        {
          title: "Risk Management",
          description: "Effective strategies to mitigate business risks",
          icon: "fa-shield-halved",
        },
      ],
    };
  },
};
</script>

<style scoped>
@keyframes smoothPulse {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.05);
  }
}

.animate-smooth-pulse {
  animation: smoothPulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-smooth-pulse-delayed {
  animation: smoothPulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  animation-delay: 1.5s;
}

@keyframes smoothFloat {
  0%,
  100% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-10px) rotate(1deg);
  }
}

.animate-smooth-float {
  animation: smoothFloat 6s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
