<template>
  <div
    class="min-h-screen bg-gradient-to-br from-slate-50 to-slate-100 relative overflow-hidden"
  >
    <div class="container mx-auto px-4 py-12">
      <div class="grid md:grid-cols-2 gap-12 items-center max-w-7xl mx-auto">
        <!-- Left Column -->
        <div class="space-y-8 animate-fade-in">
          <h1 class="font-serif text-5xl text-slate-800 leading-tight">
            Let's Start a Conversation
          </h1>
          <p class="text-slate-600 text-lg">
            Have questions? We're here to help. Reach out to us anytime and
            we'll get back to you as soon as possible.
          </p>
          <div class="space-y-6">
            <div class="flex items-center space-x-4">
              <div class="bg-slate-700 p-3 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div>
                <h3 class="font-medium text-slate-800">Email Us</h3>
                <p class="text-slate-600">info@orsonrede.com</p>
              </div>
            </div>
            <div class="flex items-center space-x-4">
              <div class="bg-slate-700 p-3 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
              <div>
                <h3 class="font-medium text-slate-800">Call Us</h3>
                <p class="text-slate-600">+(233) 558-374161</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Column - Form -->
        <div
          class="bg-white rounded-3xl shadow-xl p-8 animate-slide-up hover:shadow-2xl transition-shadow duration-300"
        >
          <form @submit.prevent="handleSubmit" class="space-y-6">
            <div class="grid md:grid-cols-2 gap-6">
              <div class="form-group">
                <label class="form-label">Full Name</label>
                <input
                  v-model="name"
                  type="text"
                  class="form-input"
                  placeholder="Enter full name"
                  required
                />
              </div>
              <div class="form-group">
                <label class="form-label">Email</label>
                <input
                  v-model="email"
                  type="email"
                  class="form-input"
                  placeholder="john@example.com"
                  required
                />
              </div>
            </div>

            <div class="grid md:grid-cols-2 gap-6">
              <div class="form-group">
                <label class="form-label">Phone Number</label>
                <input
                  v-model="phone"
                  type="tel"
                  class="form-input"
                  placeholder="+1 (123) 456-7890"
                  required
                />
              </div>
              <div class="form-group">
                <label class="form-label">Subject</label>
                <input
                  v-model="subject"
                  type="text"
                  class="form-input"
                  placeholder="Your inquiry subject"
                  required
                />
              </div>
            </div>

            <div class="form-group">
              <label class="form-label">Message</label>
              <textarea
                v-model="message"
                class="form-input min-h-[150px]"
                placeholder="Tell us about your inquiry..."
                rows="4"
              ></textarea>
            </div>

            <button
              type="submit"
              :class="[
                'w-full bg-slate-700 hover:bg-slate-600 text-white px-8 py-4 rounded-xl transform transition-all duration-300 hover:scale-[1.02] hover:shadow-lg',
                { 'animate-button-submit': isSubmitting },
              ]"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? "Sending..." : "Send Message" }}
            </button>
          </form>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div
      v-if="showSuccessModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm animate-modal-appear"
    >
      <div
        class="bg-white p-12 rounded-3xl shadow-2xl text-center max-w-md animate-modal-content"
      >
        <div class="mb-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-24 w-24 mx-auto text-green-500 animate-bounce"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <h2
          class="text-3xl font-bold text-slate-800 mb-4 animate-fade-in-delay"
        >
          Message Sent Successfully!
        </h2>
        <p class="text-slate-600 mb-6 animate-fade-in-delay-2">
          Thank you for reaching out. We'll get back to you as soon as possible.
        </p>
        <button
          @click="closeModal"
          class="bg-slate-700 hover:bg-slate-600 text-white px-8 py-3 rounded-xl transform transition-all duration-300 hover:scale-[1.02] animate-fade-in-delay-3"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AskUs",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      isSubmitting: false,
      showSuccessModal: false,
    };
  },
  methods: {
    async handleSubmit() {
      // Prevent multiple submissions
      if (this.isSubmitting) return;

      // Start submission process
      this.isSubmitting = true;

      try {
        const response = await fetch("https://formspree.io/f/xpwqkqgl", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            phone: this.phone,
            subject: this.subject,
            message: this.message,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        // Reset form and show success modal
        this.resetForm();
        this.showSuccessModal = true;
      } catch (error) {
        console.error("Submission failed:", error);
        // You might want to show an error modal or message here
      } finally {
        this.isSubmitting = false;
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.subject = "";
      this.message = "";
    },
    closeModal() {
      this.showSuccessModal = false;
    },
  },
};
</script>

<style scoped>
.form-group {
  @apply transition-all duration-300;
}

.form-label {
  @apply block text-slate-700 font-medium text-sm mb-2;
}

.form-input {
  @apply w-full p-3 border border-slate-200 rounded-xl focus:ring-2 focus:ring-slate-500 
  focus:border-transparent outline-none transition-all duration-300;
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-slide-up {
  animation: slideUp 0.8s ease-out;
}

.animate-button-submit {
  animation: buttonSubmit 0.5s infinite alternate;
}

.animate-modal-appear {
  animation: modalAppear 0.5s ease-out;
}

.animate-modal-content {
  animation: modalContent 0.6s ease-out;
}

.animate-fade-in-delay {
  animation: fadeIn 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

.animate-fade-in-delay-2 {
  animation: fadeIn 0.8s ease-out 0.4s forwards;
  opacity: 0;
}

.animate-fade-in-delay-3 {
  animation: fadeIn 0.8s ease-out 0.6s forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonSubmit {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes modalAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalContent {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.form-input:hover {
  @apply border-slate-400;
}

.form-group:focus-within {
  transform: translateY(-2px);
}
</style>
