import { ref } from "vue";

export function usePreloader() {
  const isLoading = ref(true);

  const startLoading = async () => {
    isLoading.value = true;
  };

  const stopLoading = async () => {
    isLoading.value = false;
  };

  const waitForImages = async () => {
    const images = Array.from(document.images);
    const promises = images.map((img) => {
      if (img.complete) {
        return Promise.resolve();
      }
      return new Promise((resolve) => {
        img.onload = resolve;
        img.onerror = resolve;
      });
    });
    await Promise.all(promises);
  };

  return {
    isLoading,
    startLoading,
    stopLoading,
    waitForImages,
  };
}
