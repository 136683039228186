<template>
  <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-12 md:py-20">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
      <!-- Left side with text and bars -->
      <div class="space-y-6 px-4 sm:px-6 lg:px-8">
        <!-- Yellow bar at the top -->
        <div class="relative overflow-hidden">
          <div
            class="h-1 bg-yellow-400 w-full transform transition-all duration-1000"
            data-aos="slide-right"
          ></div>
        </div>

        <!-- Main content -->
        <div class="space-y-4 md:space-y-6">
          <p
            class="text-xl md:text-2xl font-extralight pl-2 transform transition-opacity duration-700"
            data-aos="fade-up"
          >
            Learn About Our History
          </p>

          <p
            class="text-3xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-serif text-logo-color leading-tight"
            data-aos="zoom-in"
          >
            Over a Decade of Experience
          </p>

          <p
            class="text-base md:text-lg text-gray-600 max-w-2xl pt-2"
            data-aos="fade-up"
          >
            With over 10 years of industry expertise, we have a proven track
            record of excellence and a commitment to delivering quality results.
          </p>
        </div>

        <!-- Yellow bar at the bottom -->
        <div class="relative overflow-hidden">
          <div
            class="h-1 bg-yellow-400 w-full transform transition-all duration-1000"
            data-aos="slide-left"
          ></div>
        </div>
      </div>

      <!-- Right side with image -->
      <div
        class="mt-8 lg:mt-0 flex justify-center lg:justify-end"
        data-aos="fade-in"
      >
        <img
          :src="require('@/assets/about2.jpg')"
          alt="About us"
          class="w-full md:w-5/6 lg:w-full rounded-lg shadow-lg object-cover"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DecadeExpi",
};
</script>

<style scoped>
/* Add any custom styles if needed */
</style>
