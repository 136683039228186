<!-- WelcomeHero.vue -->
<script>
export default {
  name: "WelcomeHero",
  data() {
    return {
      showContactModal: false,
      contactInfo: {
        email: "info@orsonrede.com",
        phone: "+233 (558) 374-161",
      },
    };
  },
  methods: {
    toggleModal() {
      this.showContactModal = !this.showContactModal;
    },
    closeModal() {
      this.showContactModal = false;
    },
  },
};
</script>

<template>
  <div class="min-h-screen bg-white overflow-hidden">
    <!-- Abstract Background Elements -->
    <div class="absolute inset-0 -z-10">
      <!-- Gradient Overlay -->
      <div
        class="absolute inset-0 bg-gradient-to-b from-[#164364]/5 to-transparent animate-gradient"
      ></div>

      <!-- Abstract Shapes -->
      <div class="absolute w-full h-full overflow-hidden">
        <div
          class="absolute top-0 right-0 w-[800px] h-[800px] bg-[#164364]/3 rounded-full mix-blend-multiply filter blur-3xl animate-blob"
        ></div>
        <div
          class="absolute bottom-0 left-0 w-[600px] h-[600px] bg-slate-700/3 rounded-full mix-blend-multiply filter blur-3xl animate-blob animation-delay-2000"
        ></div>
      </div>

      <!-- Animated Grid Pattern -->
      <div class="absolute inset-0 grid grid-cols-6 gap-4 p-8 opacity-5">
        <div
          v-for="n in 24"
          :key="n"
          class="h-24 rounded-lg bg-[#164364] animate-pulse"
          :style="{ animationDelay: n * 100 + 'ms' }"
        ></div>
      </div>
    </div>

    <!-- Main Content -->
    <div class="relative pt-32">
      <div class="container mx-auto px-4 sm:px-6 lg:px-8">
        <!-- Center Content -->
        <div class="max-w-4xl mx-auto text-center">
          <!-- Animated Badge -->
          <div
            class="inline-flex items-center space-x-2 bg-white/80 backdrop-blur-sm px-6 py-2 rounded-full shadow-md mb-8 animate-slideDown"
          >
            <div class="flex items-center space-x-2">
              <span class="relative flex h-3 w-3">
                <span
                  class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#164364] opacity-75"
                ></span>
                <span
                  class="relative inline-flex rounded-full h-3 w-3 bg-[#164364]"
                ></span>
              </span>
              <span class="text-sm font-medium text-[#164364] tracking-wide"
                >INNOVATIVE SOLUTIONS</span
              >
              <span class="relative flex h-3 w-3">
                <span
                  class="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#164364] opacity-75 animation-delay-500"
                ></span>
                <span
                  class="relative inline-flex rounded-full h-3 w-3 bg-[#164364]"
                ></span>
              </span>
            </div>
          </div>

          <!-- Main Heading with Split Text Animation -->
          <h1
            class="text-4xl sm:text-5xl lg:text-7xl font-bold text-[#164364] leading-tight mb-6 animate-slideUp"
          >
            Welcome to
            <span class="relative inline-block mt-2 animate-revealText">
              Orson Rede
              <div
                class="absolute bottom-2 left-0 w-full h-3 bg-[#164364]/10 -rotate-1 animate-slideRight"
              ></div>
            </span>
          </h1>

          <!-- Subheading -->
          <p
            class="text-xl text-slate-600 leading-relaxed mb-12 max-w-2xl mx-auto animate-fadeIn"
          >
            Empowering businesses through strategic consulting and innovative
            solutions. Transform your vision into lasting success.
          </p>

          <!-- CTA Section -->
          <div
            class="flex flex-col sm:flex-row justify-center items-center gap-4 animate-slideUp"
          >
            <button
              @click="toggleModal"
              class="group inline-flex items-center px-8 py-4 border-2 border-[#164364] text-[#164364] rounded-xl hover:bg-[#164364]/5 transition-all duration-300"
            >
              Get In Touch
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Contact Modal -->
    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-200 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div
        v-if="showContactModal"
        class="fixed inset-0 z-50 flex items-center justify-center p-4"
      >
        <!-- Backdrop -->
        <div
          class="absolute inset-0 bg-black/20 backdrop-blur-sm"
          @click="closeModal"
        ></div>

        <!-- Modal Content -->
        <div
          class="relative bg-white rounded-2xl shadow-xl max-w-md w-full p-8 transform transition-all"
        >
          <div class="absolute top-4 right-4">
            <button
              @click="closeModal"
              class="text-slate-400 hover:text-slate-600 transition-colors"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <h3 class="text-2xl font-bold text-[#164364] mb-6">
            Contact Information
          </h3>

          <div class="space-y-6">
            <!-- Email -->
            <a
              :href="'mailto:' + contactInfo.email"
              class="flex items-center p-4 rounded-xl bg-slate-50 hover:bg-slate-100 transition-colors group"
            >
              <div
                class="w-12 h-12 flex items-center justify-center bg-[#164364] rounded-full text-white"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div class="ml-4">
                <div class="text-sm text-slate-600">Email</div>
                <div
                  class="text-[#164364] font-medium group-hover:text-[#164364]/80"
                >
                  {{ contactInfo.email }}
                </div>
              </div>
            </a>

            <!-- Phone -->
            <a
              :href="'tel:' + contactInfo.phone"
              class="flex items-center p-4 rounded-xl bg-slate-50 hover:bg-slate-100 transition-colors group"
            >
              <div
                class="w-12 h-12 flex items-center justify-center bg-[#164364] rounded-full text-white"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
              <div class="ml-4">
                <div class="text-sm text-slate-600">Phone</div>
                <div
                  class="text-[#164364] font-medium group-hover:text-[#164364]/80"
                >
                  {{ contactInfo.phone }}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
@keyframes blob {
  0%,
  100% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes revealText {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

@keyframes slideRight {
  from {
    transform: translateX(-100%) rotate(-1deg);
  }
  to {
    transform: translateX(0) rotate(-1deg);
  }
}

.animate-blob {
  animation: blob 15s infinite;
}

.animate-slideDown {
  animation: slideDown 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-slideUp {
  animation: slideUp 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-fadeIn {
  animation: fadeIn 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-revealText {
  animation: revealText 1s cubic-bezier(0.4, 0, 0.2, 1);
}

.animate-slideRight {
  animation: slideRight 1s cubic-bezier(0.4, 0, 0.2, 1) 0.5s both;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-500 {
  animation-delay: 500ms;
}

/* Ensure proper mobile display */
@media (max-width: 640px) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
