<template>
  <div
    class="min-h-screen bg-gradient-to-br from-white to-slate-50 py-16 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-7xl mx-auto">
      <div class="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
        <!-- Left Column - Hero Section -->
        <div class="space-y-8">
          <h1
            class="text-4xl md:text-5xl lg:text-6xl font-thin text-slate-800 leading-tight max-w-xl"
          >
            Expert Advisory for
            <span class="text-logo-color font-normal">Maximum Returns</span>
          </h1>
          <div class="relative">
            <button
              class="call-button bg-gradient-to-r from-teal-700 to-teal-600 text-white px-8 py-4 rounded-full text-lg font-medium transition-all duration-300 flex items-center gap-3 relative"
            >
              <svg
                class="w-6 h-6 phone-icon"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                />
              </svg>
              <span @click="callNow">Call Now</span>
              <svg
                class="w-5 h-5 transform group-hover:translate-x-1 transition-transform"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- Right Column - Features Grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-12">
          <!-- Feature 1 -->
          <div
            class="group hover:scale-105 transition-transform duration-300 p-6 rounded-xl hover:bg-white hover:shadow-xl"
          >
            <div class="w-12 h-12 mb-6">
              <svg
                class="w-full h-full text-teal-700"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
              </svg>
            </div>
            <h3
              class="text-xl lg:text-2xl font-semibold text-slate-800 mb-3 group-hover:text-teal-700 transition-colors"
            >
              Build a better future for your company
            </h3>
            <p class="text-slate-600 leading-relaxed">
              Empower your business with strategic financial planning and expert
              guidance to achieve sustainable long-term growth.
            </p>
          </div>

          <!-- Feature 2 -->
          <div
            class="group hover:scale-105 transition-transform duration-300 p-6 rounded-xl hover:bg-white hover:shadow-xl"
          >
            <div class="w-12 h-12 mb-6">
              <svg
                class="w-full h-full text-teal-700"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                />
              </svg>
            </div>
            <h3
              class="text-xl lg:text-2xl font-semibold text-slate-800 mb-3 group-hover:text-teal-700 transition-colors"
            >
              Achieve great success with our expertise
            </h3>
            <p class="text-slate-600 leading-relaxed">
              Leverage cutting-edge strategies and proven methodologies to
              maximize your business potential and market impact.
            </p>
          </div>

          <!-- Feature 3 -->
          <div
            class="group hover:scale-105 transition-transform duration-300 p-6 rounded-xl hover:bg-white hover:shadow-xl"
          >
            <div class="w-12 h-12 mb-6">
              <svg
                class="w-full h-full text-teal-700"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </div>
            <h3
              class="text-xl lg:text-2xl font-semibold text-slate-800 mb-3 group-hover:text-teal-700 transition-colors"
            >
              Trusted by over 10,000 clients and agencies
            </h3>
            <p class="text-slate-600 leading-relaxed">
              Join our network of successful clients who have transformed their
              businesses through expert financial guidance.
            </p>
          </div>

          <!-- Feature 4 -->
          <div
            class="group hover:scale-105 transition-transform duration-300 p-6 rounded-xl hover:bg-white hover:shadow-xl"
          >
            <div class="w-12 h-12 mb-6">
              <svg
                class="w-full h-full text-teal-700"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3
              class="text-xl lg:text-2xl font-semibold text-slate-800 mb-3 group-hover:text-teal-700 transition-colors"
            >
              Take control and learn about your finances
            </h3>
            <p class="text-slate-600 leading-relaxed">
              Take control of your financial destiny with personalized wealth
              management strategies and expert portfolio guidance.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvisoryLanding",
  methods: {
    callNow() {
      window.location.href = "tel:+233558374161"; // Replace with your actual phone number
    },
  },
};
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(20, 184, 166, 0.7);
  }
  70% {
    transform: scale(1.08);
    box-shadow: 0 0 0 15px rgba(20, 184, 166, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(20, 184, 166, 0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-15deg);
  }
  20% {
    transform: rotate(15deg);
  }
  30% {
    transform: rotate(-15deg);
  }
  40% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.call-button {
  animation: pulse 2s infinite, float 3s ease-in-out infinite;
  position: relative;
}

.call-button:hover {
  animation: float 3s ease-in-out infinite;
  transform: scale(1.05);
  background: linear-gradient(to right, #0f766e, #14b8a6);
  box-shadow: 0px 8px 20px rgba(15, 118, 110, 0.3);
}

.call-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(20, 184, 166, 0.5);
}

.phone-icon {
  animation: shake 1.5s ease-in-out infinite;
  display: inline-block;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}
</style>
