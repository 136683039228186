<template><do-best /><services-types /><ask-as /></template>

<script>
import AskAs from "@/components/AskAs.vue";

import DoBest from "@/components/ServiceDir/DoBest.vue";
import ServicesTypes from "@/components/ServiceDir/ServicesTypes.vue";

export default {
  components: {
    AskAs,
    DoBest,
    ServicesTypes,
  },
  name: "ServiceHero",
};
</script>

<style></style>
