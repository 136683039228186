<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 to-white">
    <!-- Background decoration -->
    <div
      class="absolute inset-0 bg-[radial-gradient(circle_at_50%_120%,rgba(120,119,198,0.1),rgba(0,0,0,0))]"
    ></div>

    <div class="container mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-24 relative">
      <!-- Header Section -->
      <div class="max-w-3xl mx-auto text-center mb-16" data-aos="fade-up">
        <div class="inline-flex items-center justify-center space-x-2 mb-4">
          <div class="h-px w-8 bg-indigo-500"></div>
          <span
            class="text-sm font-medium text-indigo-600 uppercase tracking-wider"
            >FAQ</span
          >
          <div class="h-px w-8 bg-indigo-500"></div>
        </div>
        <h1
          class="text-3xl md:text-4xl lg:text-5xl font-serif font-bold text-gray-900 mb-4"
        >
          Frequently Asked Questions
        </h1>
        <p class="text-gray-600 text-lg">
          Get answers to common questions about our financial consulting
          services
        </p>
      </div>

      <!-- FAQ Grid -->
      <div
        class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 max-w-6xl mx-auto"
      >
        <!-- Left Column -->
        <div class="space-y-6" data-aos="fade-right">
          <!-- FAQ Item 1 -->
          <div
            class="group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
            :class="{ 'ring-2 ring-indigo-500 ring-opacity-50': isOpen[1] }"
          >
            <button
              class="w-full px-6 py-5 flex items-center justify-between focus:outline-none"
              @click="toggleAnswer(1)"
            >
              <h2 class="text-xl font-semibold text-gray-800 text-left">
                How Fast Can Your Team Respond?
              </h2>
              <div class="relative ml-4">
                <div
                  class="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center"
                >
                  <svg
                    class="w-5 h-5 text-indigo-600 transform transition-transform duration-300"
                    :class="{ 'rotate-180': isOpen[1] }"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            <div
              class="overflow-hidden transition-all duration-300 ease-in-out"
              :style="{ maxHeight: isOpen[1] ? '300px' : '0px' }"
            >
              <div class="px-6 pb-5 text-gray-600">
                Our team typically responds to inquiries within 2-4 business
                hours. For urgent matters, our priority response team is
                available to address critical financial concerns within 1 hour
                during business hours. We understand that financial decisions
                often require timely guidance.
              </div>
            </div>
          </div>

          <!-- FAQ Item 3 -->
          <div
            class="group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
            :class="{ 'ring-2 ring-indigo-500 ring-opacity-50': isOpen[3] }"
          >
            <button
              class="w-full px-6 py-5 flex items-center justify-between focus:outline-none"
              @click="toggleAnswer(3)"
            >
              <h2 class="text-xl font-semibold text-gray-800 text-left">
                What Are Your Working Hours?
              </h2>
              <div class="relative ml-4">
                <div
                  class="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center"
                >
                  <svg
                    class="w-5 h-5 text-indigo-600 transform transition-transform duration-300"
                    :class="{ 'rotate-180': isOpen[3] }"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            <div
              class="overflow-hidden transition-all duration-300 ease-in-out"
              :style="{ maxHeight: isOpen[3] ? '300px' : '0px' }"
            >
              <div class="px-6 pb-5 text-gray-600">
                Our office hours are 8:30 AM to 5:30 PM GMT, Monday through
                Friday. However, we offer flexible appointment scheduling,
                including early morning and evening consultations by
                arrangement. Virtual meetings are also available for your
                convenience.
              </div>
            </div>
          </div>

          <!-- FAQ Item 5 -->
          <div
            class="group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
            :class="{ 'ring-2 ring-indigo-500 ring-opacity-50': isOpen[5] }"
          >
            <button
              class="w-full px-6 py-5 flex items-center justify-between focus:outline-none"
              @click="toggleAnswer(5)"
            >
              <h2 class="text-xl font-semibold text-gray-800 text-left">
                Can I Get a Custom Quote?
              </h2>
              <div class="relative ml-4">
                <div
                  class="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center"
                >
                  <svg
                    class="w-5 h-5 text-indigo-600 transform transition-transform duration-300"
                    :class="{ 'rotate-180': isOpen[5] }"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            <div
              class="overflow-hidden transition-all duration-300 ease-in-out"
              :style="{ maxHeight: isOpen[5] ? '300px' : '0px' }"
            >
              <div class="px-6 pb-5 text-gray-600">
                Yes, we provide personalized fee quotes based on your specific
                financial needs and portfolio size. Schedule a complimentary
                initial consultation where we'll assess your requirements and
                provide a detailed proposal tailored to your financial goals.
              </div>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="space-y-6" data-aos="fade-left">
          <!-- FAQ Item 2 -->
          <div
            class="group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
            :class="{ 'ring-2 ring-indigo-500 ring-opacity-50': isOpen[2] }"
          >
            <button
              class="w-full px-6 py-5 flex items-center justify-between focus:outline-none"
              @click="toggleAnswer(2)"
            >
              <h2 class="text-xl font-semibold text-gray-800 text-left">
                What Services Do You Offer?
              </h2>
              <div class="relative ml-4">
                <div
                  class="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center"
                >
                  <svg
                    class="w-5 h-5 text-indigo-600 transform transition-transform duration-300"
                    :class="{ 'rotate-180': isOpen[2] }"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            <div
              class="overflow-hidden transition-all duration-300 ease-in-out"
              :style="{ maxHeight: isOpen[2] ? '300px' : '0px' }"
            >
              <div class="px-6 pb-5 text-gray-600">
                We provide comprehensive financial consulting services including
                investment strategy, retirement planning, wealth management, tax
                planning, estate planning, and business financial advisory. Each
                service is tailored to meet your specific financial goals and
                circumstances.
              </div>
            </div>
          </div>

          <!-- FAQ Item 4 -->
          <div
            class="group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
            :class="{ 'ring-2 ring-indigo-500 ring-opacity-50': isOpen[4] }"
          >
            <button
              class="w-full px-6 py-5 flex items-center justify-between focus:outline-none"
              @click="toggleAnswer(4)"
            >
              <h2 class="text-xl font-semibold text-gray-800 text-left">
                How Do I Contact Support?
              </h2>
              <div class="relative ml-4">
                <div
                  class="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center"
                >
                  <svg
                    class="w-5 h-5 text-indigo-600 transform transition-transform duration-300"
                    :class="{ 'rotate-180': isOpen[4] }"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            <div
              class="overflow-hidden transition-all duration-300 ease-in-out"
              :style="{ maxHeight: isOpen[4] ? '300px' : '0px' }"
            >
              <div class="px-6 pb-5 text-gray-600">
                You can reach our client services team at info@orsonrede.com or
                call our dedicated support line at (+233) 558-374161. Each
                client is also assigned a personal financial advisor with direct
                contact information for ongoing support and consultation.
              </div>
            </div>
          </div>

          <!-- FAQ Item 6 -->
          <div
            class="group bg-white rounded-2xl shadow-sm hover:shadow-md transition-all duration-300"
            :class="{ 'ring-2 ring-indigo-500 ring-opacity-50': isOpen[6] }"
          >
            <button
              class="w-full px-6 py-5 flex items-center justify-between focus:outline-none"
              @click="toggleAnswer(6)"
            >
              <h2 class="text-xl font-semibold text-gray-800 text-left">
                Do You Provide Ongoing Support?
              </h2>
              <div class="relative ml-4">
                <div
                  class="w-8 h-8 rounded-full bg-indigo-50 flex items-center justify-center"
                >
                  <svg
                    class="w-5 h-5 text-indigo-600 transform transition-transform duration-300"
                    :class="{ 'rotate-180': isOpen[6] }"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </div>
              </div>
            </button>
            <div
              class="overflow-hidden transition-all duration-300 ease-in-out"
              :style="{ maxHeight: isOpen[6] ? '300px' : '0px' }"
            >
              <div class="px-6 pb-5 text-gray-600">
                Yes, we provide continuous portfolio monitoring, regular
                financial reviews, and ongoing advisory services. Our
                comprehensive service includes quarterly portfolio rebalancing,
                annual financial plan updates, and regular meetings to adjust
                strategies as your financial situation evolves.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinancialFAQ",
  data() {
    return {
      isOpen: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
    };
  },
  methods: {
    toggleAnswer(id) {
      this.isOpen[id] = !this.isOpen[id];
    },
  },
};
</script>

<style scoped>
.group {
  transform: translateZ(0);
}
</style>
