<template>
  <hero-carousel />
  <div>
    <explore-section />
    <explore-area /><expert-page /><experience-years /><infinite-slider />

    <AskAs />
  </div>
</template>

<script>
import HeroCarousel from "@/components/HeroCarousel/HeroCarousel.vue";
import ExperienceYears from "@/components/ExperienceYears/ExperienceYears.vue";
import InfiniteSlider from "@/components/InfiniteSlider.vue";
import ExploreSection from "@/components/ExploreSection.vue";

import AskAs from "@/components/AskAs.vue";
import ExploreArea from "@/components/ExploreArea.vue";

import ExpertPage from "@/components/ExpertPage.vue";

export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      message: "", // New data property for textarea
    };
  },
  methods: {
    handleSubmit() {
      console.log("Form Submitted");
      console.log("Name:", this.name);
      console.log("Email:", this.email);
      console.log("Phone:", this.phone);
      console.log("Subject:", this.subject);
      console.log("Message:", this.message);
    },
  },
  components: {
    ExploreSection,
    AskAs,
    ExploreArea,
    InfiniteSlider,
    ExpertPage,
    ExperienceYears,
    HeroCarousel,
  },
};
</script>

<style scoped>
/* Custom styles, if needed */
</style>
