<template>
  <footer class="bg-[#164364] text-white py-12">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
        <!-- Brand Section -->
        <div class="space-y-6 group">
          <div class="flex items-center space-x-3">
            <img
              :src="require('@/assets/logo.jpg')"
              alt="Company Logo"
              class="h-16 w-auto object-contain group-hover:scale-105 transition-transform duration-300"
            />
          </div>
          <p class="text-white leading-relaxed">
            Empowering organizations through strategic consulting, institutional
            strengthening, and innovative business solutions.
          </p>

          <!-- Social Icons with Hover Effects -->
          <div class="flex space-x-4">
            <a
              v-for="(social, index) in socialLinks"
              :key="index"
              :href="social.link"
              target="_blank"
              class="text-white hover:text-blue-200 transform hover:-translate-y-1 hover:scale-110 transition-all duration-300"
            >
              <font-awesome-icon
                :icon="['fab', social.icon]"
                class="text-2xl"
              />
            </a>
          </div>
        </div>

        <!-- Consulting Services -->
        <div class="space-y-6">
          <h3 class="text-xl font-semibold border-b-2 border-white pb-2">
            <font-awesome-icon icon="briefcase" class="mr-2" />
            Our Services
          </h3>
          <ul class="space-y-3">
            <li v-for="(service, index) in services" :key="index">
              <router-link
                :to="service.path"
                class="text-white hover:text-blue-200 hover:pl-2 transition-all duration-300 inline-block items-center"
              >
                <font-awesome-icon :icon="service.icon" class="mr-2" />
                {{ service.name }}
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Quick Links -->
        <div class="space-y-6">
          <h3 class="text-xl font-semibold border-b-2 border-white pb-2">
            <font-awesome-icon icon="link" class="mr-2" />
            Quick Links
          </h3>
          <ul class="space-y-3">
            <li v-for="(link, index) in quickLinks" :key="index">
              <router-link
                :to="link.path"
                class="text-white hover:text-blue-200 hover:pl-2 transition-all duration-300 inline-block items-center"
              >
                <font-awesome-icon :icon="link.icon" class="mr-2" />
                {{ link.name }}
              </router-link>
            </li>
          </ul>
        </div>

        <!-- Newsletter -->
        <div class="space-y-6">
          <h3 class="text-xl font-semibold border-b-2 border-white pb-2">
            <font-awesome-icon icon="envelope-open-text" class="mr-2" />
            Stay Informed
          </h3>
          <p class="text-white mb-4">
            Subscribe to receive insights, market trends, and advisory updates.
          </p>
          <form @submit.prevent="subscribeNewsletter" class="space-y-4">
            <div class="relative">
              <input
                type="email"
                v-model="email"
                placeholder="Enter your business email"
                required
                :disabled="isSubmitting"
                class="w-full px-4 py-3 bg-[#1e5784] border border-[#2464a0] text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white transition-all duration-300"
              />
              <font-awesome-icon
                icon="at"
                class="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-300"
              />
            </div>
            <button
              type="submit"
              :disabled="isSubmitting"
              class="w-full px-6 py-3 bg-white text-[#164364] hover:bg-blue-100 rounded-lg transition-all duration-300 transform hover:scale-105 flex items-center justify-center space-x-2"
              :class="{ 'animate-button-submit': isSubmitting }"
            >
              <font-awesome-icon icon="paper-plane" class="text-[#164364]" />
              <span>{{ isSubmitting ? "Subscribing..." : "Subscribe" }}</span>
            </button>
          </form>
        </div>
      </div>

      <!-- Bottom Bar -->
      <div
        class="mt-12 pt-6 border-t border-[#2464a0] flex flex-col md:flex-row justify-between items-center"
      >
        <p class="text-white text-sm mb-4 md:mb-0">
          © {{ currentYear }} Orson Rede Consulting. All rights reserved.
        </p>
        <div class="flex space-x-4">
          <router-link
            v-for="(policy, index) in policies"
            :key="index"
            :to="policy.path"
            class="text-sm text-white hover:text-blue-200 transition-colors duration-300"
          >
            {{ policy.name }}
          </router-link>
        </div>
      </div>
    </div>

    <!-- Success Modal -->
    <div
      v-if="showSuccessModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm animate-modal-appear"
    >
      <div
        class="bg-white p-12 rounded-3xl shadow-2xl text-center max-w-md animate-modal-content"
      >
        <div class="mb-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-24 w-24 mx-auto text-green-500 animate-bounce"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <h2
          class="text-3xl font-bold text-slate-800 mb-4 animate-fade-in-delay"
        >
          Successfully Subscribed!
        </h2>
        <p class="text-slate-600 mb-6 animate-fade-in-delay-2">
          Thank you for subscribing to our newsletter. You'll receive our latest
          updates soon!
        </p>
        <button
          @click="closeModal"
          class="bg-[#164364] hover:bg-[#1e5784] text-white px-8 py-3 rounded-xl transform transition-all duration-300 hover:scale-[1.02] animate-fade-in-delay-3"
        >
          Close
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "ConsultancyFooter",
  data() {
    return {
      email: "",
      isSubmitting: false,
      showSuccessModal: false,
      currentYear: new Date().getFullYear(),
      socialLinks: [
        { icon: "linkedin", link: "https://linkedin.com/company/yourcompany" },
        { icon: "twitter", link: "https://twitter.com/yourcompany" },
        { icon: "facebook", link: "https://facebook.com/yourcompany" },
      ],
      quickLinks: [
        { name: "Home", path: "/", icon: "home" },
        { name: "About", path: "/about", icon: "info-circle" },
        { name: "Services", path: "/services", icon: "briefcase" },
        { name: "Contact", path: "/contacts", icon: "address-book" },
      ],
      services: [
        {
          name: "Institutional Strengthening",
          path: "/services",
          icon: "building",
        },
        {
          name: "Business Solutions",
          path: "/services",
          icon: "chart-line",
        },
        {
          name: "Workshop Facilitation",
          path: "/services",
          icon: "chalkboard-user",
        },
        {
          name: "Business Advisory",
          path: "/services",
          icon: "handshake",
        },
        {
          name: "Accountancy Solutions",
          path: "/services",
          icon: "calculator",
        },
        {
          name: "HR Solutions",
          path: "/services",
          icon: "users-gear",
        },
      ],
      policies: [
        { name: "Privacy Policy", path: "/" },
        { name: "Terms of Service", path: "/" },
        { name: "Confidentiality", path: "/" },
      ],
    };
  },
  methods: {
    async subscribeNewsletter() {
      if (!this.email || this.isSubmitting) return;

      this.isSubmitting = true;

      try {
        const response = await fetch("https://formspree.io/f/xpwqkqgl", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.email,
            type: "newsletter_subscription",
          }),
        });

        if (!response.ok) {
          throw new Error("Subscription failed");
        }

        // Reset form and show success modal
        this.email = "";
        this.showSuccessModal = true;
      } catch (error) {
        console.error("Newsletter subscription failed:", error);
        // You might want to show an error modal here
      } finally {
        this.isSubmitting = false;
      }
    },
    closeModal() {
      this.showSuccessModal = false;
    },
  },
};
</script>

<style scoped>
.animate-button-submit {
  animation: buttonSubmit 0.5s infinite alternate;
}

.animate-modal-appear {
  animation: modalAppear 0.5s ease-out;
}

.animate-modal-content {
  animation: modalContent 0.6s ease-out;
}

.animate-fade-in-delay {
  animation: fadeIn 0.8s ease-out 0.2s forwards;
  opacity: 0;
}

.animate-fade-in-delay-2 {
  animation: fadeIn 0.8s ease-out 0.4s forwards;
  opacity: 0;
}

.animate-fade-in-delay-3 {
  animation: fadeIn 0.8s ease-out 0.6s forwards;
  opacity: 0;
}

@keyframes buttonSubmit {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes modalAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalContent {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
