import { createApp } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faChevronDown,
  faBinoculars,
  faPhone,
  faClock,
  faEnvelope,
  faLocationDot,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import App from "@/App.vue";
import "@/assets/tailwind.css";
import AOS from "aos";
import "aos/dist/aos.css";
import router from "@/router";
import VueCarousel from "vue-carousel";

// Initialize AOS
AOS.init({
  once: true,
});

// Set up FontAwesome
library.add(
  faFacebook,
  faInstagram,
  faClock,
  faWhatsapp,
  faPhone,
  faBars,
  faEnvelope,
  faLocationDot,
  faMapMarkerAlt,
  faBars,
  faChevronDown,
  faBinoculars
);

// Create intersection observer directive for scroll animations
const intersectionObserverDirective = {
  mounted(el, binding) {
    const observer = new IntersectionObserver(
      (entries) => {
        if (typeof binding.value === "function") {
          binding.value(entries);
        }
      },
      {
        threshold: 0.1,
      }
    );
    observer.observe(el);
    el._observer = observer;
  },
  unmounted(el) {
    if (el._observer) {
      el._observer.disconnect();
    }
  },
};

const app = createApp(App);

// Register global directives
app.directive("intersect", intersectionObserverDirective);

// Register plugins
app.use(router);
app.use(VueCarousel);

// Register FontAwesome component globally
app.component("font-awesome-icon", FontAwesomeIcon);

// Mount the app
app.mount("#app");
