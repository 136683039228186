<template>
  <div class="w-full overflow-x-hidden">
    <header
      class="bg-logo-color fixed w-full top-0 z-50 px-4 sm:px-6 py-4 shadow-lg backdrop-blur-sm transition-all duration-300"
      :class="{ 'shadow-xl': scrolled }"
    >
      <div class="max-w-7xl mx-auto flex justify-between items-center">
        <!-- Logo -->
        <div class="flex items-center">
          <router-link
            to="/"
            class="transform hover:scale-110 transition-all duration-500 hover:rotate-6"
          >
            <img
              :src="require('@/assets/logo.jpg')"
              alt="Logo"
              class="w-10 h-10 rounded-lg object-cover md:w-14 md:h-14 hover:shadow-2xl transition-all duration-500"
            />
          </router-link>
        </div>

        <!-- Desktop Navigation -->
        <nav
          class="hidden md:flex items-center space-x-6 lg:space-x-8 text-base lg:text-lg font-medium"
        >
          <router-link
            v-for="item in navItems"
            :key="item.path"
            :to="item.path"
            class="text-white/90 hover:text-white transition-all duration-300 relative group py-2 flex items-center space-x-2 whitespace-nowrap"
            :class="{ 'nav-active': isActiveRoute(item.path) }"
          >
            <font-awesome-icon
              :icon="item.icon"
              class="transform group-hover:rotate-12 transition-transform duration-500 w-4 h-4 lg:w-5 lg:h-5"
              :class="{ 'text-yellow-300': isActiveRoute(item.path) }"
            />
            <span class="relative overflow-hidden">
              {{ item.name }}
              <span
                class="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-yellow-300 to-yellow-500 transform transition-transform duration-500"
                :class="
                  isActiveRoute(item.path)
                    ? 'scale-x-100'
                    : 'translate-x-[-100%] group-hover:translate-x-0'
                "
              ></span>
            </span>
          </router-link>
        </nav>

        <!-- Mobile Menu Button -->
        <button
          @click="toggleMenu"
          class="md:hidden text-white p-2 rounded-lg hover:bg-white/10 transition-all duration-300 focus:outline-none transform hover:scale-110"
          :class="{ 'bg-white/10': menuOpen }"
          aria-label="Toggle Menu"
        >
          <div class="relative w-5 h-5">
            <span
              class="absolute w-full h-0.5 bg-white transform transition-all duration-500 ease-in-out"
              :class="menuOpen ? 'rotate-45 top-2.5' : 'top-0.5'"
            ></span>
            <span
              class="absolute w-full h-0.5 bg-white top-2.5 transition-all duration-500 ease-in-out"
              :class="
                menuOpen ? 'opacity-0 scale-x-0' : 'opacity-100 scale-x-100'
              "
            ></span>
            <span
              class="absolute w-full h-0.5 bg-white transform transition-all duration-500 ease-in-out"
              :class="menuOpen ? '-rotate-45 top-2.5' : 'top-4.5'"
            ></span>
          </div>
        </button>
      </div>
    </header>

    <!-- Mobile Navigation Menu -->
    <div
      class="fixed inset-0 z-40 md:hidden"
      v-if="menuOpen"
      @click.self="toggleMenu"
    >
      <!-- Backdrop -->
      <div
        class="absolute inset-0 bg-black/40 backdrop-blur-sm transition-opacity duration-300"
        :class="{ 'opacity-100': menuOpen, 'opacity-0': !menuOpen }"
      ></div>

      <!-- Menu -->
      <div
        class="absolute top-[60px] inset-x-0 bg-white/95 backdrop-blur-md shadow-xl transform transition-all duration-300"
        :class="{
          'translate-y-0 opacity-100': menuOpen,
          '-translate-y-full opacity-0': !menuOpen,
        }"
      >
        <div class="px-4 py-2 max-h-[calc(100vh-60px)] overflow-y-auto">
          <router-link
            v-for="(item, index) in navItems"
            :key="item.path"
            :to="item.path"
            @click="toggleMenu"
            class="mobile-nav-item px-4 py-3 rounded-lg transition-all duration-300 flex items-center space-x-3"
            :class="{
              'bg-indigo-100 text-indigo-600': isActiveRoute(item.path),
              'text-gray-700 hover:bg-indigo-50 hover:text-indigo-600':
                !isActiveRoute(item.path),
            }"
            :style="{ animationDelay: `${index * 100}ms` }"
          >
            <font-awesome-icon
              :icon="item.icon"
              class="w-5 h-5 transition-transform duration-300"
              :class="{ 'rotate-12': isActiveRoute(item.path) }"
            />
            <span class="text-base">{{ item.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ResponsiveNav",
  setup() {
    const menuOpen = ref(false);
    const scrolled = ref(false);
    const route = useRoute();

    const navItems = [
      { name: "Home", path: "/", icon: ["fas", "home"] },
      { name: "About", path: "/about", icon: ["fas", "info-circle"] },
      { name: "Services", path: "/services", icon: ["fas", "tools"] },
      { name: "Contact", path: "/contacts", icon: ["fas", "envelope"] },
    ];

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value;
      document.body.style.overflow = menuOpen.value ? "hidden" : "";
      if (!menuOpen.value) {
        // Reset body overflow when menu is closed
        setTimeout(() => {
          document.body.style.overflow = "";
        }, 300);
      }
    };

    const isActiveRoute = (path) => {
      return route.path === path;
    };

    const handleScroll = () => {
      scrolled.value = window.scrollY > 20;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      // Prevent mobile viewport issues
      document.documentElement.style.overflow = "hidden auto";
      document.documentElement.style.height = "100%";
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      menuOpen,
      navItems,
      scrolled,
      toggleMenu,
      isActiveRoute,
    };
  },
};
</script>

<style scoped>
/* Base styles */
:root {
  overflow-x: hidden;
  width: 100%;
}

.nav-active {
  @apply text-yellow-300 font-bold;
}

/* Mobile menu animations */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.mobile-nav-item {
  animation: slideIn 0.5s ease-out forwards;
}

/* Prevent horizontal scroll */
* {
  max-width: 100vw;
}
</style>
