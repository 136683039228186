<template>
  <div class="min-h-screen bg-gradient-to-br from-gray-50 via-white to-gray-50">
    <!-- Decorative background elements -->
    <div
      class="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none"
    >
      <div
        class="absolute top-20 left-10 w-64 h-64 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"
      ></div>
      <div
        class="absolute top-40 right-10 w-64 h-64 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"
      ></div>
      <div
        class="absolute bottom-20 left-1/2 w-64 h-64 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"
      ></div>
    </div>

    <div class="relative container mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-24">
      <!-- Hero Section with enhanced design -->
      <div class="text-center max-w-4xl mx-auto mb-20" data-aos="fade-up">
        <!-- Top decorative element -->
        <div class="flex items-center justify-center space-x-4 mb-8">
          <div
            class="h-1 w-20 bg-gradient-to-r from-blue-500 to-transparent rounded-full"
          ></div>
          <i class="fas fa-crown text-2xl text-yellow-400"></i>
          <div
            class="h-1 w-20 bg-gradient-to-l from-blue-500 to-transparent rounded-full"
          ></div>
        </div>

        <!-- Enhanced subtitle -->
        <div class="relative inline-block mb-6">
          <div
            class="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-400 blur-lg opacity-20 rounded-lg"
          ></div>
          <p
            class="relative text-lg sm:text-xl lg:text-2xl font-light px-6 py-2"
          >
            When It's Critical, You Need the Best—Choose Us.
          </p>
        </div>

        <!-- Main heading with enhanced typography -->
        <h1
          class="text-4xl sm:text-6xl lg:text-7xl font-serif font-bold mb-8 bg-clip-text text-transparent bg-gradient-to-r from-gray-800 via-gray-600 to-gray-800"
        >
          Why Choose Us
        </h1>

        <!-- Description with decorative elements -->
        <div class="relative max-w-2xl mx-auto">
          <p class="text-base sm:text-lg text-gray-600 leading-relaxed">
            Our focus on reliability, creativity, and client satisfaction sets
            us apart. We deliver tailored solutions that truly meet your needs.
          </p>
          <div
            class="absolute -bottom-6 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-transparent via-gray-300 to-transparent"
          ></div>
        </div>
      </div>

      <!-- Enhanced Cards Grid -->
      <div
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12"
      >
        <!-- Strategy Card -->
        <div
          class="group relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <!-- Card Header with enhanced design -->
          <div class="relative mb-8">
            <div
              class="absolute -top-4 -right-4 w-24 h-24 bg-blue-50 rounded-full opacity-50 group-hover:scale-150 transition-transform duration-500"
            ></div>
            <div class="relative flex items-center justify-between">
              <div class="flex items-center space-x-4">
                <div
                  class="h-16 w-16 rounded-2xl bg-gradient-to-br from-blue-500 to-blue-600 p-0.5"
                >
                  <div
                    class="h-full w-full bg-white rounded-2xl flex items-center justify-center"
                  >
                    <i
                      class="fas fa-chess text-3xl text-blue-600 group-hover:scale-110 transition-transform"
                    ></i>
                  </div>
                </div>
                <span
                  class="text-7xl font-bold text-blue-50 group-hover:text-blue-100 transition-colors"
                  >01</span
                >
              </div>
            </div>
          </div>

          <!-- Card Content with enhanced typography -->
          <h2
            class="text-2xl font-serif font-bold text-gray-800 mb-4 group-hover:text-blue-600 transition-colors"
          >
            Strategic Planning
          </h2>
          <p class="text-gray-600 leading-relaxed mb-6">
            We design detailed and tailored business plans that align with your
            unique goals. Our strategic approach ensures that you navigate
            challenges effectively.
          </p>

          <!-- Interactive elements -->
        </div>

        <!-- Professional Card -->
        <div
          class="group relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2 md:translate-y-8"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <!-- Card Header -->
          <div class="relative mb-8">
            <div
              class="absolute -top-4 -right-4 w-24 h-24 bg-purple-50 rounded-full opacity-50 group-hover:scale-150 transition-transform duration-500"
            ></div>
            <div class="relative flex items-center justify-between">
              <div class="flex items-center space-x-4">
                <div
                  class="h-16 w-16 rounded-2xl bg-gradient-to-br from-purple-500 to-purple-600 p-0.5"
                >
                  <div
                    class="h-full w-full bg-white rounded-2xl flex items-center justify-center"
                  >
                    <i
                      class="fas fa-users-gear text-3xl text-purple-600 group-hover:scale-110 transition-transform"
                    ></i>
                  </div>
                </div>
                <span
                  class="text-7xl font-bold text-purple-50 group-hover:text-purple-100 transition-colors"
                  >02</span
                >
              </div>
            </div>
          </div>

          <!-- Card Content -->
          <h2
            class="text-2xl font-serif font-bold text-gray-800 mb-4 group-hover:text-purple-600 transition-colors"
          >
            Expert Professionals
          </h2>
          <p class="text-gray-600 leading-relaxed mb-6">
            Our team of seasoned professionals offers a wealth of expertise and
            industry knowledge. We deliver innovative solutions that enhance
            your business.
          </p>

          <!-- Interactive elements -->
        </div>

        <!-- Experience Card -->
        <div
          class="group relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg hover:shadow-2xl transition-all duration-500 transform hover:-translate-y-2"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <!-- Card Header -->
          <div class="relative mb-8">
            <div
              class="absolute -top-4 -right-4 w-24 h-24 bg-pink-50 rounded-full opacity-50 group-hover:scale-150 transition-transform duration-500"
            ></div>
            <div class="relative flex items-center justify-between">
              <div class="flex items-center space-x-4">
                <div
                  class="h-16 w-16 rounded-2xl bg-gradient-to-br from-pink-500 to-pink-600 p-0.5"
                >
                  <div
                    class="h-full w-full bg-white rounded-2xl flex items-center justify-center"
                  >
                    <i
                      class="fas fa-chart-line text-3xl text-pink-600 group-hover:scale-110 transition-transform"
                    ></i>
                  </div>
                </div>
                <span
                  class="text-7xl font-bold text-pink-50 group-hover:text-pink-100 transition-colors"
                  >03</span
                >
              </div>
            </div>
          </div>

          <!-- Card Content -->
          <h2
            class="text-2xl font-serif font-bold text-gray-800 mb-4 group-hover:text-pink-600 transition-colors"
          >
            Proven Experience
          </h2>
          <p class="text-gray-600 leading-relaxed mb-6">
            With years of experience across various sectors, we provide reliable
            and actionable guidance. Our deep understanding drives your success.
          </p>

          <!-- Interactive elements -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUs",
};
</script>

<style scoped>
@keyframes blob {
  0% {
    transform: translate(0px, 0px) scale(1);
  }
  33% {
    transform: translate(30px, -50px) scale(1.1);
  }
  66% {
    transform: translate(-20px, 20px) scale(0.9);
  }
  100% {
    transform: translate(0px, 0px) scale(1);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}
</style>
