// PreLoader.vue
<template>
  <Transition name="fade">
    <div v-if="loading" class="preloader">
      <div class="loader-content">
        <!-- Animated background elements -->
        <div class="background-elements">
          <div v-for="n in 4" :key="n" class="bg-circle"></div>
        </div>

        <!-- Central logo container -->
        <div class="logo-container">
          <div class="logo-frame">
            <div class="corner top-left"></div>
            <div class="corner top-right"></div>
            <div class="corner bottom-left"></div>
            <div class="corner bottom-right"></div>
          </div>

          <!-- Animated text content -->
          <div class="text-content">
            <div class="company-name">
              <span
                v-for="(letter, index) in 'ORSON'"
                :key="'first-' + index"
                class="letter"
                :style="{ animationDelay: `${index * 0.15}s` }"
              >
                {{ letter }}
              </span>
              <span class="space"></span>
              <span
                v-for="(letter, index) in 'REDE'"
                :key="'second-' + index"
                class="letter"
                :style="{ animationDelay: `${(index + 6) * 0.15}s` }"
              >
                {{ letter }}
              </span>
            </div>
            <div class="consulting">
              <span
                v-for="(letter, index) in 'CONSULTING'"
                :key="index"
                class="letter"
                :style="{ animationDelay: `${index * 0.1 + 1.5}s` }"
              >
                {{ letter }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
const { defineProps } = require("vue");
defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
});
</script>

<style scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Background Elements */
.background-elements {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bg-circle {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(
    135deg,
    rgba(22, 67, 100, 0.1),
    rgba(22, 67, 100, 0.05)
  );
  animation: float 10s infinite ease-in-out;
}

.bg-circle:nth-child(1) {
  width: 300px;
  height: 300px;
  top: -50px;
  left: -50px;
  animation-delay: 0s;
}

.bg-circle:nth-child(2) {
  width: 200px;
  height: 200px;
  bottom: -30px;
  right: -30px;
  animation-delay: -2s;
}

.bg-circle:nth-child(3) {
  width: 150px;
  height: 150px;
  top: 50%;
  right: 15%;
  animation-delay: -4s;
}

.bg-circle:nth-child(4) {
  width: 180px;
  height: 180px;
  bottom: 20%;
  left: 10%;
  animation-delay: -6s;
}

/* Logo Container */
.logo-container {
  position: relative;
  padding: 4rem;
  z-index: 2;
}

.logo-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid rgba(22, 67, 100, 0.1);
  animation: framePulse 4s infinite ease-in-out;
}

.corner {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #164364;
  opacity: 0;
  animation: cornerFade 3s infinite ease-in-out;
}

.top-left {
  top: -2px;
  left: -2px;
  border-right: none;
  border-bottom: none;
}

.top-right {
  top: -2px;
  right: -2px;
  border-left: none;
  border-bottom: none;
  animation-delay: 0.75s;
}

.bottom-left {
  bottom: -2px;
  left: -2px;
  border-right: none;
  border-top: none;
  animation-delay: 1.5s;
}

.bottom-right {
  bottom: -2px;
  right: -2px;
  border-left: none;
  border-top: none;
  animation-delay: 2.25s;
}

/* Text Content */
.text-content {
  text-align: center;
  position: relative;
}

.company-name {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.space {
  width: 1rem;
}

.company-name .letter {
  font-size: 2.5rem;
  font-weight: 700;
  color: #164364;
}

.consulting .letter {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.3em;
  color: rgba(22, 67, 100, 0.7);
}

.letter {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  animation: letterReveal 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Animations */
@keyframes float {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(10px, 10px) rotate(2deg);
  }
  50% {
    transform: translate(0, 20px) rotate(0deg);
  }
  75% {
    transform: translate(-10px, 10px) rotate(-2deg);
  }
}

@keyframes framePulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.6;
  }
}

@keyframes cornerFade {
  0%,
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes letterReveal {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  60% {
    transform: translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Transition animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
