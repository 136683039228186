<template>
  <PreLoader :loading="isLoading" />
  <contact-hero />
</template>

<script setup>
import { onMounted } from "vue";
import ContactHero from "@/components/ContactDir.vue/ContactHero.vue";
import PreLoader from "@/shared/PreLoader.vue";
import { usePreloader } from "@/composables/usePreloader";

const { isLoading, startLoading, stopLoading, waitForImages } = usePreloader();

onMounted(async () => {
  await startLoading();
  try {
    await waitForImages();
  } catch (error) {
    console.error("Error loading contact page:", error);
  } finally {
    await stopLoading();
  }
});
</script>

<style></style>
